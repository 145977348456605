import { ARCanvas, ARMarker } from "@artcom/react-three-arjs"
import React, { useRef, useState, useEffect } from "react"
import * as THREE from "three"
import { Text } from "@react-three/drei"

const markerSize = [80, 80] // Marker size in mm
const posterSize = [210, 297] // A4 paper size in mm
const scale = 1.9
const padding = 15

const scaledSize = [
  (posterSize[0] / markerSize[0]) * scale,
  (posterSize[1] / markerSize[1]) * scale,
] // Scale to fit A4
console.log("scaledSize", scaledSize)
const scaledPaddingX = (padding / markerSize[0]) * scale
const scaledPaddingZ = (padding / markerSize[1]) * scale

var texture = new THREE.TextureLoader().load(
  "https://www.lifeblood.com.au/sites/default/files/2021-08/donatelife-article-header-1580x490.png",
)
var mat = new THREE.MeshLambertMaterial({ color: 0xbbbbff, map: texture })

const App = () => {
  const Plane = () => {
    // Place the bottom left corner of the plane at the marker
    const xPosition = scaledSize[0] / 2 / scale - 0.5
    const zPosition = -scaledSize[1] / 2 / scale + 0.5

    console.log("xPosition", xPosition)
    console.log("zPosition", zPosition)

    const height = scaledSize[1] / 6

    const heightPadding = height / 6

    return (
      <>
        {/* <mesh
          rotation={[-Math.PI / 2, 0, 0]} // Add this line to rotate the plane
          position={[xPosition - scaledPaddingX, -0.5, zPosition + scaledPaddingZ]}>
          <planeGeometry args={scaledSize} />
          <meshBasicMaterial color="red" transparent={true} opacity={0.8} />
        </mesh> */}
        <mesh
          rotation={[-Math.PI / 2, 0, 0]} // Add this line to rotate the plane
          position={[
            xPosition - scaledPaddingX,
            0,
            zPosition - scaledSize[1] / 2 + scaledPaddingZ + (height + heightPadding) * 0,
          ]}>
          <boxGeometry args={[scaledSize[0], height, 0.3]} />
          <meshBasicMaterial color="hotpink" />
          <Text
            fontSize={0.4}
            color="black"
            position={[0, 0, 0.3]}
            anchorX="center"
            anchorY="middle">
            DonateLife
          </Text>
        </mesh>
        <mesh
          rotation={[-Math.PI / 2, 0, 0]} // Add this line to rotate the plane
          position={[
            xPosition - scaledPaddingX,
            0,
            zPosition - scaledSize[1] / 2 + scaledPaddingZ + (height + heightPadding) * 1,
          ]}
          onClick={() => {
            window.open("https://www.donatelife.gov.au/all-about-donation/who-can-donate", "_blank").focus();
          }}>
          <boxGeometry args={[scaledSize[0], height, 0.3]} />
          <meshBasicMaterial color="red" />
          <Text
            fontSize={0.3}
            color="white"
            position={[0, 0, 0.3]}
            anchorX="center"
            anchorY="middle">
            Who can donate?
          </Text>
        </mesh>
        <mesh
          rotation={[-Math.PI / 2, 0, 0]} // Add this line to rotate the plane
          position={[
            xPosition - scaledPaddingX,
            0,
            zPosition - scaledSize[1] / 2 + scaledPaddingZ + (height + heightPadding) * 2,
          ]}
          onClick={() => {
            window.open("https://www.donatelife.gov.au/all-about-donation/who-will-be-helped-my-donation", "_blank").focus();
          }}>
          <boxGeometry args={[scaledSize[0], height, 0.3]} />
          <meshBasicMaterial color="red" />
          <Text
            fontSize={0.3}
            color="white"
            position={[0, 0, 0.3]}
            anchorX="center"
            anchorY="middle">
            Who will be helped by my donation?
          </Text>
        </mesh>
        <mesh
          rotation={[-Math.PI / 2, 0, 0]} // Add this line to rotate the plane
          position={[
            xPosition - scaledPaddingX,
            0,
            zPosition - scaledSize[1] / 2 + scaledPaddingZ + (height + heightPadding) * 3,
          ]}
          onClick={() => {
            window.open("https://www.donatelife.gov.au/all-about-donation/how-does-donation-work", "_blank").focus();
          }}>
          <boxGeometry args={[scaledSize[0], height, 0.3]} />
          <meshBasicMaterial color="red" />
          <Text
            fontSize={0.3}
            color="white"
            position={[0, 0, 0.3]}
            anchorX="center"
            anchorY="middle">
            How does donation work?
          </Text>
        </mesh>
        <mesh
          rotation={[-Math.PI / 2, 0, 0]} // Add this line to rotate the plane
          position={[
            xPosition - scaledPaddingX,
            0,
            zPosition - scaledSize[1] / 2 + scaledPaddingZ + (height + heightPadding) * 4,
          ]}
          onClick={() => {
            window.open("https://www.donatelife.gov.au/all-about-donation/talk-your-family-about-donation", "_blank").focus();
          }}>
          <boxGeometry args={[scaledSize[0], height, 0.3]} />
          <meshBasicMaterial color="red" />
          <Text
            fontSize={0.3}
            color="white"
            position={[0, 0, 0.3]}
            anchorX="center"
            anchorY="middle">
            Talk to your family about donation
          </Text>
        </mesh>
        <mesh
          rotation={[-Math.PI / 2, 0, 0]} // Add this line to rotate the plane
          position={[
            xPosition - scaledPaddingX,
            0,
            zPosition - scaledSize[1] / 2 + scaledPaddingZ + (height + heightPadding) * 5,
          ]}
          onClick={() => {
            window.open("https://www.donatelife.gov.au/all-about-donation/statistics-in-australia", "_blank").focus();
          }}>
          <boxGeometry args={[scaledSize[0], height, 0.3]} />
          <meshBasicMaterial color="red" transparent={true} opacity={0.5} />
          <Text
            fontSize={0.3}
            color="white"
            position={[0, 0, 0.3]}
            anchorX="center"
            anchorY="middle">
            Statistics in Australia
          </Text>
        </mesh>
      </>
    )
  }

  return (
    <ARCanvas
      gl={{ antialias: false, powerPreference: "high-performance" }}
      onCameraStreamReady={() => console.log("Camera stream ready")}
      onCameraStreamError={() => console.error("Camera stream error")}
      onCreated={({ gl }) => {
        gl.setSize(window.innerWidth, window.innerHeight)
      }}>
      <ARMarker
        params={{ smooth: true }}
        type="barcode"
        barcodeValue={5}
        onMarkerFound={() => {
          console.log("Marker Found")
        }}>
        <Plane />
      </ARMarker>
    </ARCanvas>
  )
}

export default App
